<template>
    <div>
        <CCards
            icon="fa fa-filter"
            bIcon="fa fa-file"
            caption="Datos del Filtro"
            btn_name="ocultar"
            btn_name1="ocultar"
        >
            <CRow>
                <CCol sm="4">
                    <CInput  
                        label="Fecha Inicio"  
                        type="date" 
                        v-model="filtro.dateini"
                    />
                </CCol>
                <CCol sm="4">
                    <CInput 
                        label="Fecha Final"  
                        type="date" 
                        v-model="filtro.datefin" 
                        :min="filtro.dateini"
                    />
                </CCol>
                <CCol sm="3">
                    <CButton 
                        type="button" 
                        :disabled="vue.btnestado == 0 ? true : false" 
                        :class="btnClasses" 
                        style="margin-top:2em;width:100% !important" 
                        @click="loadTable">
                        <i class="fas fa-search"></i>&nbsp; Filtrar
                    </CButton>
                </CCol>
            </CRow>
        </CCards>

        <CRow>
            <CCol sm="12">
                <CTableWrapper
                    :items="data"
                    :fields="fields"
                    hover
                    striped
                    border
                    small
                    fixed
                    :caption="vue.texto"
                    icon="fa fa-file"
                    btn_name="ocultar"
                    :showhide_buttons="vue.showhide"
                    :size="'md'"
                    @action_ver="down_item_pdf"
                />
            </CCol>
        </CRow>
    </div>
</template>

<script>
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CCards from "../../components/shared/containers/Cards.vue";
import { mapActions } from "vuex";
import { bus } from '../../main'

export default {
    components: { CTableWrapper, CCards },
    data() {
        return{
            vue: {
                btnestado: 1,
                texto: 'Resultados del Filtro',
                showhide: [{
                    "btn_ver": 1, 
                    "btn_pass": 0, 
                    "btn_edit": 0, 
                    "btn_del": 0 
                }] // dato para los estados de los botones
            },
            filtro: {
                dateini: '',
                datefin: ''
            },
            fields: [
                "Id",
                "Proyecto",
                "Cliente",
                "Monto_Total",
                "Monto_Inicial",
                "Descuento"
            ],
            data: [],
        }
    },
    computed:{
        btnClasses () {
            return [`btn btn-${this.color || 'primary'}`]
        }
    },
    created(){
        this.mtd_getdata();
    },
    methods:{
        ...mapActions(["get","post"]),
        mtd_getdata: function(){
            let actual = new Date();
            let fecha;
            fecha = actual.getFullYear() + '-'
                    + ('0' + (actual.getMonth()+1)).slice(-2) + '-'
                    + ('0' + actual.getDate()).slice(-2);
            this.filtro.dateini = fecha;
            this.filtro.datefin = fecha;

            this.loadTable();
        },
        loadTable: function(){
            let fini, ffin;
            fini = this.filtro.dateini;
            ffin = this.filtro.datefin;

            this.post({
                url: this.$store.getters.get__url+"/sale/report",
                token: this.$store.getters.get__token,
                params:{
                    fecha_ini: fini,
                    fecha_fin: ffin,
                } 
            }).then((response)=>{
                this.data = response.data;
            }).catch((errors)=>{

            });
        },
        down_item_pdf: function(id){
            this.post({
                url: this.$store.getters.get__url + "/reports/sales",
                token: this.$store.getters.get__token,
                params:{
                    id: id,
                }
            }).then((response) => {
                window.open("/assets/sales/"+response+".pdf", '_blank');
            }).catch((errors) => {});
        },
    }
}
</script>